<template>
  <div>
    <v-card
      class="primary custom-font image-card"
      flat
      style="position:relative;"
    >
        <v-icon
        class="black--text"
        large
        v-if="$route.name !== 'Login'"
        style="position: absolute; top:10px;left:20px;"
        

        @click="$router.go(-1)"
        >mdi-arrow-left-drop-circle-outline</v-icon
      >
     <v-card-text class=""
      >
        <v-img
          :src="require('../../assets/logoWhite.png')"
          width="125px"
          contain
          class="mx-auto mt-md-16 mb-5"
        />
      </v-card-text>
      <v-card-title>
        <v-row :class="$vuetify.breakpoint.mdAndDown ?
          'justify-center mx-5' : 'ms-4'" >
          <div class="custom-font text-h6"
            :class="$vuetify.breakpoint.mdAndUp ? '' : 'mx-2'"
          >
          <p class="mb-3 " > {{ $t('image.healthy') + ' ' }} </p>
          <p class="mb-3"> {{ $t('image.personalized') + ' ' }} </p>
          <p class="mb-3"> {{ $t('image.mealPlanning') + ' ' }} </p>
          <p class="mb-3"> {{ ' ' + $t('image.madeEasy')}} </p>
          </div>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-img
          :src="imgUrl"
          width="300"
          height="250"
          contain
          class="ms-n8"
        />
        <v-row :class="$vuetify.breakpoint.mdAndDown ?
          'justify-center mt-8' : 'ms-0 ms-md-4 mt-8'">
          <small class="text-body-1">{{ $t('image.footer1') }} <br v-if="$vuetify.breakpoint.mdAndUp">
            {{  $t('image.footer2') }}</small>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
        imgUrl : require('../../assets/images/pngegg (7) 1.png')
    }
  },
  computed: {
    marginSize () {
      let size = 'ml-4'
      if (this.$vuetify.breakpoint.md) size = 'ml-4'
      if (this.$vuetify.breakpoint.lgAndUp) size = 'ml-6'
      if (this.$vuetify.breakpoint.sm) size = 'ml-0'
      if (this.$vuetify.breakpoint.xs) size = 'ml-0'
      return size
    }
  },
  mounted () {
    
    switch (this.$route.name) {
      case 'Health':
        this.imgUrl = require('../../assets/images/pngegg (3) 1.png')
        break;
      case 'Menu':
        this.imgUrl = require('../../assets/images/pngegg (7) 1.png')
        break;
    }
  }
}
</script>

<style scoped>
/* .custom-title {
  margin-top: 300px !important;
} */
/* .logo-img {
  margin-top: 50px !important;
} */
.image-card {
  border-radius: 20px !important;
}
</style>